import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */

export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const ProjectCard = makeShortcode("ProjectCard");
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2>{`Written by me`}</h2>
    <ProjectCard title="The one with the diabetes" link="/blog/the-one-with-the-diabetes/" bg="linear-gradient(to right, #21130d 0%, #FBB03B 100%)" mdxType="ProjectCard">
  It's curious to think how humans evolved from apes and became self aware. But
  did it happen by accident or someone helped us? 🤔
    </ProjectCard>
    <ProjectCard title="Gatsby - what i'm trying to underst..." link="/blog/gatsby/" bg="linear-gradient(to right, #662D8C 0%, #ED1E79 100%)" mdxType="ProjectCard">
  A brief introduction to what i'm learning about the framework, I use on a
  daily basis. The ups and downs, features, drawbacks and challenges.
    </ProjectCard>
    <ProjectCard title="Memory Leak issue" link="/blog/react-memory-leak/" bg="linear-gradient(to right, #662D8C 0%, #ED1E79 100%)" mdxType="ProjectCard">
  If you have used React hooks, you would have come across this issue. Why it
  happens and how to fix it?
    </ProjectCard>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      